<template>
    <div class="w-100" style="height: 100vh">
        <div class="text-center pb-5 h-25 border">
            assdadas header asdasddsadas
        </div>
        <div class="iframe-container">
            <iframe src="/result?w=619339bb5fb5cb5ac90076be" title="" class="field-iframe" scrolling="no"
                    transparency="0" id="iframe-field-pbundle-bm-iframe-0" name="iframe-field-pbundle-bm-iframe-0"
                    style="overflow: hidden;" width="100%" height="2000" frameborder="0">
                Your browser does not support iframes, but you can use the following link.
                <a href="https://stage.millerchillwin.com.au/" title="">Link</a>
            </iframe>
        </div>
        <div class="text-center pt-5 h-25 border">
            dsfaafds footer asdfadfs
        </div>
    </div>
</template>

<script>
import iframeResize from 'iframe-resizer'

export default {
    name: 'iFrameTest',
    mounted() {
        iframeResize.iframeResize({ log: false }, '#iframe-field-pbundle-bm-iframe-0')
    }
}
</script>

<style scoped>
/*.iframe-container {*/
/*    margin: 0 auto;*/
/*    max-width: 1100px;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/

/*iframe {*/
/*    width: 1px;*/
/*    min-width: 100%;*/
/*}*/
</style>
